.dashboard-container {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.grid-container {
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 columns */
  gap: 20px;
  padding: 10px;
}

.chart-card {
  background: #ffffffd9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Responsive Design: 2 columns for tablets, 1 column for mobile */
@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
