@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
:root {
  --BG_ACTIVE: #0f5eff;
  --BG_HOVER: #0b48c0;
  --BORDER_COLOR: #d9d9d96e;
  --small-font: 10px;
  --regular-font: 11px;
  --medium-font: 12px;
  --big-header: 19px;
  --medium-header: 18px;
  --small-header: 16px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 3px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background: #c7c7c7 !important;
  border-radius: 10px !important;
}

.main-app-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

textarea {
  font-weight: 200;
}

.reschedule-calendar {
  max-width: 100%;
  font-size: 12px !important; 
  background: #F2F2F2 !important;
  border: none !important;
}

.reschedule-calendar .react-calendar__tile {
  padding: 5px !important; /* Reduce padding */
  font-size: 12px !important; /* Smaller font size for tiles */
}

.reschedule-calendar .react-calendar__month-view__days__day {
  font-size: 12px !important;
  column-gap: 13px !important;
}

.reschedule-calendar .react-calendar__navigation button {
  font-size: 14px !important; /* Adjust navigation buttons */
}

.reschedule-calendar .react-calendar__tile:enabled:hover,
.reschedule-calendar .react-calendar__tile:enabled:focus {
  background-color: #f0f0f0; /* Light hover effect */
}

.reschedule-calendar .react-calendar__tile--active {
  background-color: #444444 !important; /* Highlight selected date */
  color: white !important;
  border-radius: 5px;
}

.reschedule-calendar .react-calendar__tile {
  color: black ; /* Make all dates black */
  font-size: 14px !important; /* Adjust font size */
  padding: 8px !important; /* Adjust padding */
}

/* Style for the selected date */
.selected-date {
  background-color: black !important; /* Selected date background */
  color: white !important; /* Text color */
  border-radius: 5px;
  font-weight: bold;
}

.myDiv1 {
  box-shadow: none !important;
  background: white !important;
}

.search-location-item {
  transition: 0.25s all;
  background: white;
}

.search-location-item:hover {
  background: whitesmoke;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

textarea::placeholder {
  text-transform: capitalize;
  font-size: 12px;
  color: #1e1e1e80;
  background-color: transparent;
}

.input-date {
  border: 1px solid var(--BORDER_COLOR);
  padding: 5px 12px;
  width: 110px;
  background-color: #f3f3f3;
  font-size: 13px;
  gap: 25px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  z-index: 1000;
}

/* ViewPilotHistoryOnMap.css */
.blinking-marker {
  animation: blinking 1.5s infinite;
}

@keyframes blinking {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.input-date-hidden {
  background-color: transparent;
  width: 90px;
  outline: none !important;
  border: none !important;
  cursor: pointer;
}

.input-date-service {
  width: 100%;
  border-radius: 5px;
  height: 29.6px;
  justify-content: space-between;
}

.input-checkbox {
  width: 25px;
  height: 25px;
  border: none;
  outline: none !important;
  cursor: pointer;
}

input:focus {
  outline: var(--BG_ACTIVE) solid 1px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@layer base {
  img {
    @apply inline-block;
  }
}

body {
  margin: 0;
  font-family: "Manrope";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollbar-div::-webkit-scrollbar {
  width: 20px;
}

.scrollbar-div::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-div::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.scrollbar-div::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.filetrs-container::-webkit-scrollbar {
  height: 2px;
}

.dropdownItem {
  background-color: white;
}

.dropdownItem:hover {
  transition: 0.25s all;
  background-color: #ebf5f4;
}

.dropdown-list {
  max-height: 500px;
}

.main-container-height {
  height: 89%;
}

.form-stepper {
  width: 100%;
  padding: 0;
}

.transition {
  transition: 0.5s all !important;
}

#tv-attr-logo {
  display: none !important;
}

.button {
  color: white;
  border-radius: 4px;
  padding: 4px 12px;
  cursor: pointer;
  background-color: var(--BG_ACTIVE);
  outline: none;
  height: 25px;
  font-size: 11px;
  font-family: "Manrope";
  font-weight: 500;
}

.btn-large {
  height: 35px;
  width: 100px;
  font-size: 14px;
}

.lg-cancel-btn {
  border: 1px solid rgba(191, 200, 217, 1) !important;
  color: rgba(96, 101, 113, 1) !important;
}

.transparent-btn {
  background-color: rgba(255, 255, 255, 0.207);
  border: none;
  font-size: 10px;
  padding: 5px 4px;
}

.transparent-btn:hover {
  background-color: rgba(255, 255, 255, 0.433) !important;
  border: none !important;
  font-size: 10px;
  padding: 5px 4px;
}

.transparent-btn-disabled {
  background-color: rgba(255, 255, 255, 0.207);
  color: #a8bbbf;
}

.transparent-btn-disabled:hover {
  background-color: rgba(255, 255, 255, 0.207) !important;
  color: #a8bbbf;
}

.btn-not-active {
  border: 1px solid var(--BG_ACTIVE);
  background-color: transparent;
  color: var(--BG_ACTIVE);
}

.btn-not-active:hover {
  background-color: rgb(246, 246, 246) !important;
}

.button:hover {
  background-color: var(--BG_HOVER);
}

.settledButton:hover {
  background-color: var(--BG_HOVER);
}

.filter-btn {
  background-color: rgba(59, 59, 59, 0.1);
  border: none;
  color: #3b3b3b;
  font-weight: 600;
  padding: 4px 14px !important;
  height: 40px;
  width: 110px;
  font-size: 13px !important;
}

.filter-btn-inner {
  gap: 6px !important;
}

.filter-btn:hover {
  background-color: rgba(59, 59, 59, 0.2);
}

.revenue-btn-active {
}

.form-step-active span {
  color: white !important;
}

.stepper span {
  line-height: 35px !important;
}

.form-link {
  transition: 0.25s all;
  color: var(--BG_ACTIVE);
}

.form-link:hover {
  color: var(--BG_HOVER);
}

.popup-animation-content {
  animation: popup-animate 0.3s;
}

.popup-content {
  border: none !important;
}

.loading {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 100;
}

.input {
  font-weight: 500;
  color: #1e1e1e !important;
  font-size: 13px;
  background-color: #f3f3f3;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  box-shadow: 0 0 0px 1000px #f3f3f3 inset !important;
  -webkit-text-fill-color: 11ee1e !important;
  transition: background-color 5000s ease-in-out 0s;
}

.input::placeholder {
  text-transform: capitalize;
  font-size: 12px;
  color: #1e1e1e80;
  background-color: transparent;
}

.table-container {
  border: 1px solid var(--BORDER_COLOR);
  margin-top: 10px;
  border-radius: 6px;
}

table {
  width: 100%;
  color: rgb(89, 89, 89);
  border-radius: 5px;
}

table th {
  color: rgba(30, 30, 30, 1);
  font-weight: 500;
  font-size: 12px;
  text-align: left;
}

.table-row,
table tr {
  transition: 0.25s all;
  width: 100%;
  height: 38px;
  font-size: 10px;
  color: black;
  vertical-align: middle;
  background: white;
}

.table_header_row {
  outline: 1px solid var(--BORDER_COLOR);
  position: sticky;
  top: 0px;
  z-index: 1;
  position: sticky;
  top: 0px;
  z-index: 1;
  background: #dbe5fb !important;
}

table tr:hover {
  cursor: default;
}

.table-row-hover:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.04);
}

td {
  font-size: 11px;
  text-align: left;
  border-bottom: 1px solid var(--BORDER_COLOR);
}

table thead,
table tbody tr {
  display: table;
  table-layout: fixed;
}

@keyframes popup-animate {
  0% {
    transform: scale(0.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

#RFS-StepButton {
  /* font-size: 12px; */
}

#RFS-StepButton span {
  font-size: 12px;
}

#RFS-StepperContainer {
  padding: 0;
}

#RFS-Label {
  color: #1e1e1e80;
  font-weight: 500;
  margin-top: 10px;
}

.react-toggle-track {
  width: 65px !important;
  height: 35px !important;
}

.react-toggle-thumb {
  width: 15px !important;
  height: 15px !important;
  left: 1px !important;
}

.react-toggle--checked .react-toggle-thumb {
  left: 22px !important;
}

#login-container {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  height: 344px;
  width: 390px;
  border-radius: 5px;
  border: 1px solid var(--BORDER_COLOR);
  box-shadow: 0px 1px 4px 2px #76767626;
  background: white;
}

.select-service-card {
  transition: 0.25s all;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.49);
}

.select-service-card:hover {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.49);
}

.form-btn {
  width: 100%;
  border-radius: 2px;
  border: 1px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  margin-top: 70px;
}

.form-btn.not-active {
  background-color: white;
  width: 100%;
  border-radius: 2px;
  border: 1px solid var(--BG_ACTIVE);
  color: var(--BG_ACTIVE);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  margin-top: 70px;
}

/* toogle switch */
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #848484;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #dadada;
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--BG_ACTIVE);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--BG_ACTIVE);
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
}

/*  custom toggle button style  */

.header_toggle .react-toggle-track {
  width: 81px !important;
  height: 45px !important;
  padding: 0;
  border-radius: 45px !important;
  background: #848484 !important;
  border: 1px solid #b0b0b0;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.header_toggle .react-toggle-thumb {
  top: 8px !important;
  left: 7px !important;
  width: 30px !important;
  height: 30px !important;
}

.header_toggle .react-toggle--checked .react-toggle-track {
  background: #0ab006 !important;
  border: 1px solid #b4ffb2;
}

.header_toggle .react-toggle--checked .react-toggle-thumb {
  left: 44px !important;
  top: 8px !important;
  /* border-color: #19AB27; */
}

.leaflet-top {
  display: none;
}

.leaflet-control-attribution {
  display: none;
}

.dashboard-card img {
  transition: 0.5s all;
  scale: 1.1;
}

.dashboard-card:hover img {
  scale: 1.3;
}

.profile-pic {
  transition: 0.25s all;
  opacity: 1;
}

/* Pagination css */

.pagination-container {
  display: flex;
  list-style-type: none;
}

.pagination-item {
  height: 20px;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 10px;
  width: 20px;
}

.pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.pagination-item.selected {
  background-color: #0f5eff;
  color: white;
}

.pagination-item .arrow::before {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.pagination-item .arrow.right {
  transform: rotate(45deg);
  margin-bottom: 2px;
}

.pagination-item.disabled {
  pointer-events: none;
}

.pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.location-img {
  cursor: pointer;
  transition: 0.5s all;
  scale: 1;
}

.location-img:hover {
  scale: 1.1;
}

.drone-animation {
  transition: 0.5s all;
  width: 240px;
  position: absolute;
  top: 50%;
  right: -5%;
  animation: droneAnim 1s cubic-bezier(0.04, 0, 0, 0.97) 1 forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(50%, -50%);
}

.drone-line-animation {
  transition: 0.5s all;
  height: 20px;
  opacity: 0;
  animation: droneLineAnim 1s 1s ease-out 1 forwards;
}

.drone-msg {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  scale: 0;
  animation: droneMsgAnim 0.5s ease-out 1 forwards;
}

.illustration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

@keyframes droneAnim {
  0% {
    right: -5%;
  }

  100% {
    right: 50%;
  }
}

@keyframes droneLineAnim {
  0% {
    height: 20px;
    opacity: 0;
  }

  20%,
  50%,
  80%,
  100% {
    height: 60px;
    opacity: 1;
  }

  40% {
    height: 40px;
    opacity: 1;
  }

  60% {
    height: 50px;
    opacity: 1;
  }
}

@keyframes droneMsgAnim {
  0% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}

.pagination-right {
  padding: 10px;
  justify-content: right;
}

/* Calender CSS */
.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: 3px solid rgb(217 217 217 / 32%);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 8px;
  height: 100%;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  height: 50px !important;
  /* flex: 0 0 11.2857% !important;  */
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  padding: 50px 50px 40px 50px;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days {
  row-gap: 7px;
  column-gap: 24px;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em 0.5em 1.5em 0.5em;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-calendar__tile abbr {
  font-size: 20px !important;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
  flex: 0 0 11.2857% !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view {
  padding: 10px 40px 5px 40px;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__navigation__label {
  font-size: 19px !important;
  font-weight: 600;
  color: #1e1e1e;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
  /* flex: 0 0 11.2857% !important; */
}

.react-calendar__year-view__months {
  gap: 53px !important;
  padding: 10px 40px 30px 40px !important;
}

.react-calendar__year-view__months .react-calendar__tile--hasActive {
  flex: 0 0 17.2857% !important;
}

.react-calendar__year-view__months .react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  height: 50px !important;
  flex: 0 0 17.2857% !important;
}

.react-calendar__year-view__months .react-calendar__tile {
  max-width: 100%;
  padding: 10px 10px !important;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  position: relative;
  flex: 0 0 18.2857% !important;
}

.react-calendar__year-view__months .react-calendar__tile--now {
  background: #ffff76;
  flex: 0 0 18.2857% !important;
}

.react-calendar__year-view__months .react-calendar__tile:enabled:hover {
  background-color: #e6e6e6;
  flex: 0 0 18.2857% !important;
}

.react-calendar__year-view__months .react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  flex: 0 0 18.2857% !important;
}

.react-calendar__year-view__months .react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #ababab;
  flex: 0 0 18.2857% !important;
}

.react-calendar__year-view__months .react-calendar__tile--now:enabled:hover {
  background: #ffffa9;
  flex: 0 0 18.2857% !important;
}

.react-calendar__year-view__months .react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
  flex: 0 0 18.2857% !important;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 10px !important;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  position: relative;
  flex: 0 0 11.2857% !important;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #ababab;
  flex: 0 0 11.2857% !important;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  flex: 0 0 11.2857% !important;
}

.react-calendar__tile--now {
  background: #ffff76;
  flex: 0 0 11.2857% !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
  flex: 0 0 11.2857% !important;
}

.react-calendar__tile--hasActive {
  background: #76baff;
  flex: 0 0 11.2857% !important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
  flex: 0 0 11.2857% !important;
}

.react-calendar__tile--active {
  background: #3b3b3b;
  color: white;
  flex: 0 0 11.2857% !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #3b3b3b;
  flex: 0 0 11.2857% !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
  flex: 0 0 11.2857% !important;
}

.react-calendar__decade-view__years {
  padding: 10px 40px 30px 40px !important;
  gap: 65px !important;
}

.react-calendar__decade-view__years .react-calendar__tile {
  font-size: 19px !important;
}

/* custom calender styles  */
.request_calendar
  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  position: absolute;
  right: 120px !important;
}

.request_calendar
  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  position: absolute;
  top: 10px !important;
  right: 80px !important;
}

.request_calendar .react-calendar__navigation {
  position: relative;
  height: 44px;
  margin-bottom: 1em;
  padding: 10px 50px 40px 50px;
}

.request_calendar .react-calendar__navigation__label {
  min-width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  padding-left: 55px;
  font-size: 19px;
  font-weight: 600;
  color: #1e1e1e;
}

.request_calendar .react-calendar__tile {
  color: rgba(59, 59, 59, 1);
}

.request_calendar .react-calendar__tile--active {
  color: white;
}

.revenue-select {
  border: 1px solid #d9d9d9;
  background-color: white;
  border-radius: 8px !important;
  padding: 5px 10px !important;
  color: #000;
  font-weight: 600;
}

.sideBarOptions {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 50px;
  margin: 0 0 20px 0;
}

.ps-menu-icon {
  margin-right: 0 !important;
}

.css-9wfig9 {
  border: none !important;
}

.css-12w9als {
  margin-left: 10px !important;
}

.collapse_menu_wrapper {
  width: 75%;
  margin: auto auto;
}

.collapse-sidebar .ps-menu-button .ps-menu-icon {
  margin-left: 10px;
}

.expand-sidebar {
  padding-left: 4px !important;
}

.extended_menu_wrapper {
  width: 90%;
}

.sideBarIcon,
.sideBarIcon-not-selected {
  height: 20px;
}

.ps-menu-button:hover {
  background: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
}

/* .sideBarIcon-not-selected {
  filter: brightness(0) saturate(100%) invert(20%) sepia(3%) saturate(4%) hue-rotate(5deg) brightness(93%) contrast(87%) !important;
} */

/* .sideBarOptions:hover {
  background-color: rgba(255, 255, 255, 0.2);
  width: 98%;
} */

.underlined-text::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  /* Adjust the thickness of the underline */
  /* Adjust the color of the underline */
  bottom: 4px;
  left: 0;
  opacity: 0.5;
  /* Adjust the opacity of the underline (0.0 to 1.0) */
  background-color: #b3ccff;
}

.underlined-bg-active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  /* Adjust the thickness of the underline */
  /* Adjust the color of the underline */
  bottom: 2px;
  left: 0;
  opacity: 0.3;
  /* Adjust the opacity of the underline (0.0 to 1.0) */
  background-color: var(--BG_ACTIVE);
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.selected-service-card {
  transition: 0.5s all;
  filter: contrast(2);
}

.icon-text-container {
  gap: 3px !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.btn-text-container {
  font-weight: 600;
}

.underlined-text::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: white;
  bottom: 0;
  left: 0;
  top: 13px;
  opacity: 0.4;
}

.menu-option:hover {
  background-color: #0f5eff17;
}

/* react-toggle css */

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 35px !important;
  height: 20px !important;
  padding: 0;
  border-radius: 30px;
  background-color: #848484;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #848484;
}

.react-toggle--checked .react-toggle-track {
  background-color: #0ab006;
  /* border: 1px solid #93B7FF; */
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #0ab006;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 4px !important;
  left: 3px !important;
  width: 12px !important;
  height: 12px !important;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 18px !important;
  top: 4px !important;
  border: none !important;
}

.underline-bar::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 1;
}

.tippy {
  background-color: transparent !important;
  padding: 0px !important;
}

.tippy-tooltip[data-out-of-boundaries] {
  opacity: 0;
}

.pac-container {
  left: 24% !important;
  top: 30.5% !important;
  width: 34% !important;
  box-shadow: 0px 0px 4px 0px #75757526 !important;
  font-family: "Manrope" !important;
  font-size: 15px !important;
  background: #fcfcfc !important;
  border-radius: 7px !important;
}

.pac-item {
  padding: 5px 15px;
  cursor: pointer;
  border-bottom: none;
}

.pac-item:hover {
  background-color: #f0f0f0;
}

.pac-item-query {
  font-weight: bold;
  color: #2b2b2b;
}

.pac-icon {
  display: none;
}

.pac-item + .pac-item {
  border-top: none;
}

.pac-item:first-child {
  border-top: none;
}

.pac-container:after {
  display: none;
}

.service-card-style {
  display: inline-block;
  font-weight: 50;
  flex-direction: column;
  width: 395px;
  height: 540px;
  border-radius: 10px;
  background-color: rgba(252, 252, 252, 1);
  box-shadow: 0px 0px 6px 2px #75757526;
}

.service-card-heading-style {
  padding: 30px 25px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
}

.service-card-heading-text-style {
  font-weight: 600;
  font-size: 22px;
}

.addDrone_form_wrapper {
  width: 100%;
}

.add_drone_form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 5px;
  padding: 12px 30px;
}

.add_drone_from_label {
  font-size: 13px;
  color: #3b3b3b;
}

.drone_add_error-messsage {
  color: red;
  font-size: var(--small-font);
  height: 15px;
}

.add_drone_input_field {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 3px;
}

.add_drone_text_input {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 5px;
}

.text_input_field {
  resize: none;
  height: 35px !important;
}

.input-default {
  display: flex;
  color: #3b3b3b;
  background-color: #f3f3f3;
  width: 100%;
  height: 40px;
  border: 1.4px solid rgba(217, 217, 217, 0.84);

  border-radius: 3px;
  overflow: hidden;
  padding: 5px 10px;
  align-items: center;
}

.input-default:focus-within {
  outline: 1px solid #0f5eff;
}

.input-default-disabled {
  background: #f6f6f6;
}

.text_input_label {
  padding-right: 20px;
  font-size: 13px;
  color: #3b3b3b;
}

.buttons_wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding: 15px 30px;
}

.customer_email {
  display: inline-block;
  width: 250px;
  word-wrap: break-word;
  line-height: 1.4;
}

.pro_sidebar_options_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pro_sidebar_wrapper.css-bpn7co {
  border: none;
}

.reusable-table-view-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-main-card {
  cursor: pointer;
  border-radius: 7px;
  background: white;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.dashboard-main-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  transform: translateY(-5px); /* Slight lift effect */
  z-index: 10; /* Ensures it appears in front of other elements */
}

.dashboard-card-padding {
  padding: 17px 17px 0;
}

.dashboard-main-card-animation {
  display: block;
  position: relative;
  height: 56%;
  opacity: 1;
  width: 100%;
  transition: top 0.5s cubic-bezier(1, 2.34, 0.61, 0.87);
}

.profile-menu-hover {
  transition: background-color 0.3s ease;
}

.profile-menu-hover:hover {
  background-color: #0f5eff1a;
}

.flight-graph-container-new {
  margin: 14px 0px;
  position: relative;
  width: 100%;
  background: #fcfcfc;
  padding: 10px 30px;
  box-shadow: 0px 0px 4px 0px #0000001a;
  height: 500px;
  min-height: 500px;
  max-width: 100%;
  overflow: hidden;
}

.revenue_graph_container{
  margin: 14px 0px;
  position: relative;
  width: 100%;
  background: #fcfcfc;
  padding: 10px 30px;
  box-shadow: 0px 0px 4px 0px #0000001a;
  height: 440px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dashboard-card-parent {
  border-radius: 7px;
  background: white;
  padding: 17px 21px 17px 17px;
  width: 100%;
  height: 5.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: rgba(98, 115, 43, 0.16) 0px 0px 8px 0px;
}

.orders-card-parent {
  border-radius: 7px;
  background: white;
  padding: 17px;
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: rgba(98, 115, 43, 0.16) 0px 0px 8px 0px;
}

.orders_card_parent-header {
  display: flex;
  width: 100%;
  align-items: center;
}

.dashboard-card-parent-inner1,
.dashboard-card-parent-inner2,
.dashboard-card-parent-inner3 {
  transition: all 0.6s linear;
}

.dashboard-card-parent-inner3 {
  height: 46px;
}

.dashboard-card-parent:hover {
  background-color: #0f5eff;
  transition: all 0.6s linear;
}

.dashboard-card-parent:hover .dashboard-card-parent-inner1 {
  color: white;
}

.dashboard-card-parent:hover .dashboard-card-parent-inner2 {
  color: white;
}

.dashboard-card-parent:hover .dashboard-card-parent-inner3-container {
  background-color: white;
}

.nav-bar {
  color: #3b3b3b;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 65px 10px 25px;
  background-color: white;
  align-items: center;
  box-shadow: 0px 0px 8px 2px #4b4b4b26;
  height: 50px;
}

.dashBoardWrapper {
  padding: 25px 40px;
  height: 100%;
  width: 100%;
  background: #eceff3;
  overflow: auto;
}

.dashboard-company-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.dashboard-wellcome-text {
  font-size: 10px;
  color: #727272;
}

.dashboard-heading {
  margin-bottom: 22px;
}

.dashboard-first-section {
  display: flex;
  justify-content: left;
  width: 100%;
  gap: 17px;
  height: 205px;
  margin-bottom: 22px;
}

.dashboard-second-section {
  display: flex;
  justify-content: left;
  width: 100%;
  gap: 17px;
  height: 281px;
  margin-bottom: 22px;
}

.dashboard-first-section-first-div {
  width: 50%;
  border-radius: 6px;
  height: 100%;
  display: flex;
  justify-content: left;
  gap: 17px;
}

.dashboard-first-section-second-div {
  width: 50%;
}

.dashboard-first-section-second-div-one {
  display: flex;
  gap: 17px;
  justify-content: space-between;
  margin-bottom: 17px;
}

.dashboard-first-section-second-div-two {
  display: flex;
  gap: 17px;
  justify-content: space-between;
}

.dashboard-third-section {
  display: flex;
  justify-content: left;
  width: 100%;
  gap: 17px;
  height: 290px;
}

.dashboard-second-section-first-div {
  width: 50%;
  border-radius: 5px;
  background: white;
  height: 100%;
  box-shadow: rgba(98, 115, 43, 0.16) 0px 0px 8px 0px;
  padding: 17px;
}

.dashboard-cards-heading {
  color: #3b3b3b;
  font-size: 0.7rem;
  font-weight: 500;
}

.orders-cards-heading {
  color: #3b3b3b;
  font-size: 0.7rem;
  font-weight: 500;
}

.dashboard-cards-main-data {
  font-size: 1.4rem;
  font-weight: 600;
}

.dashboard-cards-sub-data {
  font-size: 10px;
  font-weight: 500;
  color: #727272;
}

.dashboard-graph-heading {
  margin-left: 0px;
}

.user-details-filter-text {
  font-size: 16px;
  font-weight: 500;
}

.profile-details-wrapper {
  border-radius: 8px;
  box-shadow: 0px 1px 4px 2px #76767626;
  background: white;
  width: 536px;
  height: 700px;
}

.otp_auth_message {
  font-size: 10px;
  font-weight: 400;
  width: 383px;
}

.sidebar_container {
  border-radius: 8px;
  box-shadow: 0px 1px 4px 2px #76767626;
  background: white;
  width: 279px;
  height: 700px;
}

.sidebar_wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.wallet_details_wrapper {
  border-radius: 8px;
  width: 1100px;
  box-shadow: 0px 1px 4px 2px #76767626;
  background: white;
  height: 700px;
}

.wallet_details {
  width: 100%;
  padding: 30px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.notifications_wrapper {
  position: absolute;
  top: 7%;
  right: 11%;
  background: white;
  border: 1px solid #eaeaea;
  border-radius: 14px;
  width: 590px;
  font-size: 11px;
  box-shadow: 0px 0px 4px 4px #75757526;
}

.notifications_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 28px;
  border-bottom: 1px solid #bfc8d9;
}

.notifications_header h2 {
  font-size: 20px;
  font-weight: 600;
}

.notifications_list_wrapper {
  padding: 20px 28px 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 550px;
  overflow: auto;
  position: relative;
}

.single_notification_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.unseen_notification {
  position: absolute;
  color: #0c55e9;
  font-size: 40px;
  right: 20px;
}

.single_notification_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.single_notification_title {
  font-weight: 600;
  font-size: 16px;
  color: #1e1e1e;
}

.single_notification_message {
  font-weight: 500;
  font-size: 15px;
  padding-bottom: 8px;
  margin-top: 5px;
  line-height: 1.6;
}

.single_notification_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2px;
}

/* .display: "flex", gap: "0.6rem", alignItems: "center"  */

.notification_date_time_wrapper {
  display: flex;
  gap: 0.6rem;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #727272;
}

.view_request_button {
  color: #0f5eff;
  font-size: 15px;
  font-weight: 600;
}

.view_request_inactive {
  opacity: 0.6;
  cursor: not-allowed;
}

.notifications_empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  font-size: 19px;
}

.notifications_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 28px;
  border-bottom: 1px solid #bfc8d9;
}

.notifications_header h2 {
  font-size: 20px;
  font-weight: 600;
}

.notifications_header_buttons {
  width: 45%;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  align-items: center;
  gap: 2.5rem;
}

.notification_clear_btn {
  color: #0c55e9;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.notifications_list_wrapper {
  padding: 20px 28px 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 550px;
  overflow: auto;
  position: relative;
}

.single_notification_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.unseen_notification {
  position: absolute;
  color: #0c55e9;
  font-size: 40px;
  right: 20px;
}

.single_notification_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.single_notification_title {
  font-weight: 600;
  font-size: 16px;
  color: #1e1e1e;
}

.single_notification_message {
  font-weight: 500;
  font-size: 15px;
  padding-bottom: 8px;
  margin-top: 5px;
  line-height: 1.6;
}

.single_notification_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2px;
}

/* .display: "flex", gap: "0.6rem", alignItems: "center"  */

.notification_date_time_wrapper {
  display: flex;
  gap: 0.6rem;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #727272;
}

.view_request_button {
  color: #0f5eff;
  font-size: 15px;
  font-weight: 600;
}

.view_request_inactive {
  opacity: 0.6;
  cursor: not-allowed;
}

.notifications_empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-wraper-container {
  width: 100%;
  height: 100%;
  padding: 36px 56px;
  display: flex;
  background: #eceff3;
  flex-direction: column;
  overflow: auto;
}

.main-wraper-header {
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  color: #3b3b3b;
  margin-bottom: 5px;
}

.userDetailsWrapper-drone-img {
  position: absolute;
  right: 0px;
  top: 9px;
}

.service_date_input {
  width: 100%;
  /* min-height: 100px; */
}

.service_date_input input {
  width: 280px;
  background: rgba(243, 243, 243, 1);
  height: 45px;
  outline: none !important;
  font-weight: 500;
  color: #1e1e1e !important;
  font-size: 13px;
  width: 100% !important;
  padding: 0px 8px;
  border: 1.4px solid rgba(217, 217, 217, 0.84);
  cursor: pointer;
}

.userDetailsWrapper {
  height: 219px;
  width: 100%;
  background: white;
  border-radius: 8px;
  position: relative;
  padding: 35px;
}

.service-card-header-icon {
  height: 40px;
}

.service-card-containt-wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  color: #3b3b3b;
  row-gap: 20px;
}

.service-card-containt-icon {
  width: 27px;
}

.service-card-containt-labels {
  font-size: 15px;
  color: #1e1e1e;
  font-weight: 500;
}

.canvas_container canvas {
  max-width: 100%;
}

.sprying-service-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  border-bottom: 1px solid rgba(236, 239, 243, 1);
}

.revenue-first-section {
  margin: 7px 0 32px 0;
}

.revenue-first-section-label {
  color: #3b3b3b;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 7px;
}

.revenue-first-section-toggle-bar {
  display: flex;
  height: 2.6rem;
  border: 1px solid #d9d9d9;
  width: 710px;
  border-radius: 12px;
  background: white;
}

.revenue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 100%;
  border-top: "1px solid #D9D9D9";
  font-size: 1rem;
  border-bottom: "1px solid #D9D9D9";
}

.revenue-second-section {
  width: 100%;
  margin-bottom: 30px;
}

.revenue-second-section-dropdown-label {
  width: 125px;
  margin-right: 10px;
  color: #3b3b3b;
  font-size: 1rem;
  font-weight: 500;
}

.revenue-third-section {
  margin-bottom: 15px;
}

.revenue-third-section-wraper {
  gap: 12px;
}

.revenue-third-section-heading {
  color: #1e1e1e;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.revenue-third-section-card-holder {
  display: grid;
  grid-template-columns: auto auto auto;
  height: 7.2rem;
  width: 64.4rem;
  gap: 1.7rem;
}

.revenue-statistic-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  background: white;
  padding: 15px;
}

.revenue-statistic-card-title {
  height: 100%;
  width: 100%;
  color: #848484;
  font-size: 1rem;
}

.revenue-graph-wraper {
  width: 50%;
  height: 100%;
  box-shadow: rgba(98, 115, 43, 0.1) 0px 0px 8px 2px;
  background: white;
  border-radius: 10px;
  padding: 40px 10px 10px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.revenue-second-section-drone {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.request-over-view-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  height: 320px;
}

.request-progress-card-icon {
  width: 45px;
  z-index: 1;
}

.request-progress-card-data {
  font-weight: 700;
  font-size: 22px;
  color: #1e1e1e;
}

.request-table-header-title {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 50%;
}

.request-table-header-title-div {
  font-size: 18px;
  font-weight: 600;
  color: #1e1e1e;
  width: 15%;
  min-width: fit-content;
}

.card-arrow {
  height: 100%;
}

.request_fields_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px 40px;
  align-items: start;
  font-size: 13px;
  padding: 25px 30px;
}

.single_field_wrapper {
  display: flex;
  flex-direction: column;
  gap: 13px;
  max-width: 220px;
  word-wrap: break-word;
}

.single_field_label {
  color: #3b3b3b;
  font-family: "Manrope";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2px;
}

.single_field_value {
  font-family: "Manrope";
  color: #1e1e1e;
  font-weight: 500;
  font-size: 16px;
}

.request_details_footer {
  display: flex;
  align-items: center;
  position: absolute;
  /* bottom: 20px; */
  right: 35px;
  gap: 10px;
  /* padding: 15px 30px; */
}

.request_details_modify_btn {
  outline: none;
  width: 140px;
  height: 32px;
  font-size: 15px;
}

/* Vertical stepper UI */
.vertical-stepper {
  width: 100%;
  height: calc(100% - 40px);
  margin: 20px 0;
  overflow: auto;
  position: relative;
  padding: 0px 30px;
}

.steps {
  display: flex;
  flex-direction: column;
  /* border-left: 2px solid green; */
  /* padding-left: 20px; */
}

.step {
  position: relative;
  padding: 0 30px 11px;
  border-left: 2px solid green;
}

.step-dot {
  content: "";
  position: absolute;
  left: -8px;
  top: 0;
  width: 14px;
  height: 14px;
  background-color: #ccc;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #23c329;
  border-color: #23c329;
}

.step-number {
  position: absolute;
  left: -25px;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.step-content {
  margin: -5px 0 5px 0;
}

.step-label {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 600;
}

.controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.controls button {
  padding: 10px 20px;
  font-size: 16px;
}

.controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Vertical stepper UI end */

#chartjs-doughnut-graph {
  position: relative;
  z-index: 2;
}

.chartjs-legend li {
  cursor: pointer;
  /* Set cursor to pointer for legend items */
}

.sidebar_tooltip_custom {
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: #000;
  padding: 2px 8px;
  border-radius: 4px;
  transition: all 0.1s ease-in-out;
}

.sidebar_tooltip_custom .tippy-arrow {
  color: #2b2929;
  /* Arrow color */
}

.profile_pic_wrapper {
  position: relative;
}

.profile_pic_wrapper .img_container {
  position: absolute;
  bottom: 3px;
  right: 0;
  cursor: pointer;
}

.visibility-toggle {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.visibility-label {
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  margin-bottom: 8px;
}

.visibility-options {
  display: flex;
  gap: 16px;
}

.radio-option {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-radio {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  transition: border-color 0.2s ease;
}

.custom-radio.selected {
  border-color: #1d4ed8;
}

.radio-dot {
  width: 10px;
  height: 10px;
  background-color: #1d4ed8;
  border-radius: 50%;
}

.radio-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.radio-label {
  font-size: 14px;
  color: #4a4a4a;
}

.list_view_wrapper {
  background-color: #0f5eff;
}

.pilots_list_view_wrapper {
  background-color: #0f5eff;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.map_view_wrapper {
  background-color: #0f5eff;
}

.pilot_map_view_wrapper {
  background-color: #0f5eff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin_request_chip_input {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.send_notifications_wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  gap: 5px 35px;
  padding: 10px 15px;
  overflow: auto;
}

.orders_stats_cards_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.date-range-Filters{
  width :"50%";
}

.chips-container {
  width: 70%;
  max-width: 65%;
  display: flex;
  gap: 8px; /* Adjust the gap between chips as needed */
  overflow-x: auto;
  flex-wrap: wrap;
  padding-bottom: 8px; /* Add some padding to accommodate the scrollbar */
}

.chips-container::-webkit-scrollbar {
  height: 4px; /* Reduce scrollbar height */
}

.chips-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 2px; /* Rounded corners for the thumb */
}

.chips-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scrollbar track color */
}

@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .css-9wfig9.ps-collapsed {
    min-width: 100px !important;
    width: 100px !important;
  }

  .request-table-header-title-div {
    font-size: 18px;
  }

  .request-table-header-title {
    gap: 30px;
  }

  .request-progress-card-data {
    font-size: 22px;
    color: #1e1e1e;
  }

  .request-progress-card-icon {
    width: 45px;
    z-index: 1;
  }

  .request-over-view-wrapper {
    gap: 20px;
    height: 285px;
  }

  .revenue-second-section-drone {
    gap: 1.5rem;
  }

  .sprying-service-header {
    padding: 20px 50px;
  }

  .service-card-containt-labels {
    font-size: 15px;
  }

  .service-card-containt-icon {
    width: 27px;
  }

  .service-card-containt-wrapper {
    padding: 25px 30px;
    row-gap: 20px;
  }

  .underlined-bg-active {
    font-size: 17px;
  }

  .userDetailsWrapper-drone-img {
    position: absolute;
    right: 0px;
    top: 9px;
  }

  .user-details-first-wrapper-avtar {
    height: 70px;
    border-radius: 50%;
  }

  .userDetailsWrapper {
    height: 219px;
    padding: 35px;
  }

  .revenue-graph-wraper {
    padding: 40px 25px 10px;
  }

  .revenue-third-section {
    margin-bottom: 15px;
  }

  .revenue-statistic-card-title {
    font-size: 1rem;
  }

  .revenue-statistic-card {
    gap: 10px;
    padding: 15px;
  }

  .revenue-third-section-heading {
    font-size: 1.2rem;
    margin-bottom: -3px;
  }

  .revenue-third-section-wraper {
    gap: 12px;
  }

  .revenue-third-section-card-holder {
    height: 7.2rem;
    width: 64.4rem;
    gap: 1.7rem;
  }

  .revenue-second-section-dropdown {
    width: 475px;
    background: #f3f3f3;
    height: 30px;
    min-height: 30px;
    border: 1px solid #d9d9d9;
    color: black;
    font-size: 13px;
  }

  .revenue-second-section-dropdown-label {
    width: 125px;
    margin-right: 10px;
    font-size: 1rem;
  }

  .revenue-second-section {
    margin-bottom: 30px;
  }

  .revenue-btn {
    font-size: 1rem;
  }

  .revenue-first-section-toggle-bar {
    height: 2.6rem;
    width: 710px;
  }

  .revenue-first-section-label {
    font-size: 1rem;
    margin-bottom: 7px;
  }

  .revenue-first-section {
    margin: 7px 0 32px 0;
  }

  .main-wraper-header {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .main-wraper-container {
    height: calc(100% - 50px);
    padding: 36px 56px;
  }

  .resource-visualization-cards-heading {
    margin-top: 13px;
    margin-left: 15px;
  }

  .dashboard-cards-sub-data {
    font-size: 0.9rem;
  }

  .dashboard-graph-heading {
    margin-left: 8px;
    margin-top: 6px;
  }

  .dashboard-card-parent-inner3-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    transition: 0.5s all;
    height: 4rem;
    background-color: rgb(59 130 246 / 16%);
    border-radius: 7px;
  }

  .orders-card-parent-inner3-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    transition: 0.5s all;
    height: 3rem;
    border-radius: 7px;
  }

  .dashboard-card-parent-inner3 {
    height: 4rem;
  }

  .orders-card-parent-inner3 {
    height: 2.8rem;
  }

  .dashboard-cards-main-data {
    font-size: 2rem;
  }

  .dashboard-cards-heading {
    font-size: 1rem;
  }

  .dashboard-card-padding {
    padding: 25px 25px 0;
  }

  .dashboard-third-section {
    gap: 24px;
    height: 390px;
  }

  .dashboard-first-section-second-div-two {
    gap: 24px;
  }

  .dashboard-first-section-second-div-one {
    gap: 24px;
    margin-bottom: 24px;
  }

  .dashboard-card-parent {
    height: 8.2rem;
    padding: 22px 33px 22px 25px;
  }

  .dashboard-first-section-first-div {
    gap: 24px !important;
  }

  .dashboard-first-section {
    gap: 24px;
    margin-bottom: 33px;
    height: 285px;
  }

  .dashboard-second-section {
    gap: 24px;
    margin-bottom: 33px;
    height: 398px;
  }

  .dashboard-second-section-first-div {
    width: 50%;
    border-radius: 5px;
    background: white;
    height: 100%;
    box-shadow: rgba(98, 115, 43, 0.16) 0px 0px 8px 0px;
    padding: 24px;
  }

  .dashboard-heading {
    margin-bottom: 30px;
  }

  .dashboard-wellcome-text {
    font-size: 15px;
  }

  .dashboard-company-name {
    font-size: 22px;
    margin-bottom: 3px;
  }

  .dashBoardWrapper {
    padding: 35px 55px;
  }

  .nav-bar {
    height: 62px;
  }
}

@media screen and (max-width: 1366px) {
  .main-app-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .orders_stats_cards_wrapper {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 15px;
  }

  .orders-card-parent-inner3-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    transition: 0.5s all;
    height: 3rem;
    border-radius: 7px;
  }

  .orders-card-parent {
    height: 6rem;
    box-shadow: rgba(98, 115, 43, 0.16) 0px 0px 8px 0px;
  }

  .flight-graph-container-new {
    height: 380px;
    min-height: 500px;
    max-width: 1500px;
  }

  /* .admin_request_chip_input {
    width: 400px;
  } */

  .request-table-header-title-div {
    font-size: 15px;
    width: 20%;
  }

  .request-table-header-title {
    gap: 15px;
  }

  .request-progress-card-data {
    font-size: 16px;
    color: #1e1e1e;
  }

  .request-progress-card-icon {
    width: 35px;
    z-index: 1;
  }

  .request-over-view-wrapper {
    gap: 20px;
    height: 220px;
  }

  .revenue-second-section-drone {
    gap: 12.6rem;
  }

  .dropdown-list {
    max-height: 300px;
  }

  .sprying-service-header {
    padding: 20px 30px;
  }

  .service-card-containt-labels {
    font-size: 12px;
  }

  .service-card-containt-icon {
    width: 25px;
  }

  .service-card-containt-wrapper {
    padding: 25px 25px;
    row-gap: 15px;
  }

  .underlined-bg-active {
    font-size: 13px;
  }

  .service-card-header-icon {
    height: 28px;
  }

  .service-card-style {
    display: inline-block;
    font-weight: 50;
    flex-direction: column;
    width: 18rem;
    height: 560px;
    border-radius: 10px;
    background-color: rgba(252, 252, 252, 1);
    box-shadow: 0px 0px 6px 2px #75757526;
  }

  .notifications_wrapper {
    top: 6%;
    right: 17%;
    width: 500px;
  }

  .notifications_list_wrapper {
    padding: 25px 22px 10px;
    gap: 1.5rem;
    height: 450px;
  }

  .notifications_header h2 {
    font-size: 18px;
  }

  .notifications_header {
    padding: 22px 30px;
  }

  .single_notification_title {
    font-size: 15.5px;
  }

  .unseen_notification {
    font-size: 30px;
  }

  .view_request_button {
    font-size: 14px;
  }

  .userDetailsWrapper-drone-img {
    position: absolute;
    right: 0px;
    top: 9px;
    height: 48px;
  }

  .user-details-first-wrapper-avtar {
    height: 50px;
    border-radius: 50%;
  }

  .userDetailsWrapper {
    height: 155px;
    padding: 15px 27px;
  }

  .revenue-graph-wraper {
    padding: 40px 10px 10px 10px;
  }

  .revenue-third-section {
    margin-bottom: 10px;
  }

  .revenue-statistic-card-title {
    font-size: 0.7rem;
  }

  .revenue-statistic-card {
    gap: 10px;
    padding: 10px;
  }

  .revenue-third-section-wraper {
    gap: 10px;
  }

  .revenue-third-section-card-holder {
    height: 5rem;
    width: 45.8rem;
    gap: 1.2rem;
  }

  .revenue-third-section-heading {
    font-size: 0.9rem;
    margin-bottom: -3px;
  }

  .revenue-second-section-dropdown {
    width: 339px;
    background: #d9d9d9;
    height: 30px;
    min-height: 30px;
    border: 1px solid #d9d9d9;
    color: black;
    font-size: 13px;
  }

  .revenue-second-section-dropdown-label {
    width: 85px;
    margin-right: 10px;
    font-size: 12px;
  }

  .revenue-second-section {
    margin-bottom: 20px;
  }

  .revenue-btn {
    font-size: 11px;
  }

  .revenue-first-section-toggle-bar {
    height: 1.8rem;
    width: 505px;
  }

  .revenue-first-section-label {
    font-size: 0.7rem;
    margin-bottom: 4px;
  }

  .revenue-first-section {
    margin: 0 0 22px 0;
  }

  .main-wraper-header {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .main-wraper-container {
    height: calc(100% - 42px);
    padding: 29px 40px;
  }

  .resource-visualization-cards-heading {
    margin-top: 9px;
    margin-left: 9px;
  }

  .dashboard-second-section-first-div {
    width: 50%;
    border-radius: 5px;
    background: white;
    height: 100%;
    box-shadow: rgba(98, 115, 43, 0.16) 0px 0px 8px 0px;
    padding: 17px;
  }

  .dashboard-third-section {
    gap: 17px;
    height: 290px;
  }

  .dashboard-card-parent-inner3 {
    height: 46px;
  }

  .dashboard-first-section {
    gap: 17px;
    height: 205px;
    margin-bottom: 22px;
  }

  .dashboard-second-section {
    gap: 17px;
    height: 281px;
    margin-bottom: 22px;
  }

  .dashboard-card-padding {
    padding: 17px 17px 0;
  }

  .dashboard-cards-main-data {
    font-size: 1.4rem;
    font-weight: 600;
  }

  .dashboard-cards-heading {
    color: #3b3b3b;
    font-size: 0.7rem;
    font-weight: 500;
  }

  .dashboard-graph-heading {
    margin-left: 0px;
    margin-top: 0px;
  }

  .dashboard-cards-sub-data {
    font-size: 10px;
  }

  .dashboard-first-section-second-div-two {
    gap: 17px;
  }

  .dashboard-first-section-second-div-one {
    gap: 17px;
    margin-bottom: 17px;
  }

  .dashboard-card-parent {
    height: 5.9rem;
    padding: 17px 21px 17px 17px;
  }

  .dashboard-first-section-first-div {
    gap: 17px !important;
  }

  .dashboard-first-section {
    gap: 17px;
    height: 205px;
  }

  .dashboard-heading {
    margin-bottom: 22px;
  }

  .dashboard-wellcome-text {
    font-size: 10px;
  }

  .dashboard-company-name {
    font-size: 16px;
    margin-bottom: 2px;
  }

  .dashBoardWrapper {
    padding: 25px 40px;
  }

  .sideBarOptions {
    margin: 0 0 5px 0;
  }

  .sideBarIcon,
  .sideBarIcon-not-selected {
    height: 17px !important;
  }

  .nav-bar {
    padding: 10px 65px 10px 40px;
    height: 42px;
  }

  .css-9wfig9.ps-collapsed {
    min-width: 71px !important;
    width: 71px !important;
  }

  .main-container-height {
    height: 83%;
  }

  .service-card-style {
    width: 18rem;
    height: 410px;
  }

  .add_drone_form .css-b62m3t-container {
    width: 250px !important;
    max-width: 250px !important;
  }

  .add_drone_form .input-default {
    width: 250px;
    max-width: 250px;
  }

  .add_drone_form .css-vhuqo1-control {
    width: 250px;
    max-width: 250px;
  }

  .add_drone_form .css-3iigni-container {
    width: 250px;
    max-width: 250px;
  }

  .service-card-heading-style {
    padding: 22px 20px;
  }

  .service-card-heading-text-style {
    font-size: 16px;
  }

  .wallet_details_wrapper {
    width: 940px;
    height: 600px;
  }

  .sidebar_container {
    width: 250px;
    height: 600px;
  }

  .sidebar_wrapper {
    padding: 25px 15px;
  }

  .profile-details-wrapper {
    width: 460px;
    height: 600px;
  }

  .otp_auth_message {
    width: 335px;
  }

  .notifications_header_buttons {
    font-size: 16px;
  }

  .notification_clear_btn {
    font-size: 14px;
  }
}


@media screen and (max-width: 1550px) {
  .customer_email {
    width: 150px;
  }
}

@media screen and (max-width: 1600px) {
  /* .admin_request_chip_input {
    width: 410px;
  } */

  .react-calendar__month-view__days {
    column-gap: 20px;
    row-gap: 8px;
  }
}

@media screen and (max-height: 895px) {
  .locations__wrapper {
    height: 150px !important;
    min-height: 150px !important;
  }

  .locations_container {
    grid-template-rows: repeat(auto-fill, 35px) !important;
    row-gap: 25px !important;
  }
}

@media screen and (max-height: 910px) {
  .courses_wrapper {
    height: 350px !important;
    min-height: 350px !important;
  }
}

@media screen and (max-height: 950px) {
  .pac-container {
    top: 31.5% !important;
  }
}

@media screen and (max-height: 850px) {
  .send_notifications_wrapper {
    gap: 20px 35px;
  }

  .pac-container {
    top: 33% !important;
  }
}

@media screen and (max-height: 750px) {
  .send_notifications_wrapper {
    gap: 45px 35px;
  }

  .courses_wrapper {
    height: 250px !important;
    min-height: 250px !important;
  }

  .pac-container {
    top: 34.8% !important;
  }

  .request_fields_wrapper {
    gap: 15px 40px;
    position: relative;
    font-size: 13px;
    overflow: auto;
    height: 100%;
    width: 100%;
  }

  .single_field_wrapper {
    gap: 7px;
  }

  .single_field_label {
    font-size: 15px;
  }

  .single_field_value {
    font-size: 15px;
  }

  .request_details_footer {
    /* padding: 0px 30px 10px 30px; */
  }

  .request_details_modify_btn {
    width: 130px;
    height: 30px;
    font-size: 13px;
  }
}

@media screen and (max-height: 600px) {
  .text_input_field {
    height: 30px !important;
  }

  .add_drone_form {
    row-gap: 1px;
  }

  .add_drone_from_label {
    font-size: 12px;
  }

  .buttons_wrapper {
    padding: 10px 30px;
  }

  .add_drone_form {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 1550px) {
  .customer_email {
    width: 150px;
  }
}

@media screen and (max-width: 1600px) {
  .pac-container {
    left: 19.5% !important;
    width: 18.5% !important;
  }

  .react-calendar__month-view__days {
    column-gap: 16px;
    row-gap: 8px;
  }
}

@media screen and (min-height: 1000px) {
  .pac-container {
    top: 29% !important;
  }
}

@media screen and (min-height: 1100px) {
  .pac-container {
    top: 28% !important;
  }
}

@media (min-width: 1366px) and (max-width: 1500px) {
  .sidebar_container {
    width: 250px;
  }

  .profile-details-wrapper {
    width: 500px;
  }

  .wallet_details_wrapper {
    width: 1000px;
  }
}
